import React, { useEffect } from 'react'
import Head from 'next/head'
import type { NextPage } from 'next'
import { LandingLayout } from '../components/Layout'
import { login } from '../utils/api'
import { useRouter } from 'next/router'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { Field, Form, Formik } from 'formik'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

const Login = () => {
  const router = useRouter()
  const { currentUser, refetch } = useCurrentUser()

  useEffect(() => {
    if (currentUser) {
      router.push('/directory')
    }
  }, [currentUser, router])

  const { mutate, isLoading } = useMutation<
    unknown,
    Error,
    { email: string; password: string }
  >((values) => login(values.email, values.password), {
    onSuccess: () => {
      refetch()
      toast.success('Logged in successfully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true
      })
    },
    onError: () =>
      alert(
        'Invalid email or password (password must be at least 12 characters)'
      )
  })

  return (
    <div className="h-full">
      <Head>
        <title>Login</title>
        <meta name="description" content="Log in to Breeding Market" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="min-h-full flex flex-col justify-center py-20 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-lg">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4  sm:rounded-lg sm:px-10">
            <Formik
              initialValues={{
                email: '',
                password: ''
              }}
              onSubmit={(values) => mutate(values)}
            >
              <Form className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email address
                  </label>
                  <div className="mt-1">
                    <Field
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password
                  </label>
                  <div className="mt-1">
                    <Field
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                {/* <div className="flex items-center justify-between">
                <div className="text-sm">
                  <a
                    href="#"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot your password?
                  </a>
                </div>
              </div> */}

                <div>
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Sign in
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

Login.getLayout = function getLayout(page: NextPage) {
  return (
    <LandingLayout showDots={true} showLogin={true} showFooter={false}>
      {page}
    </LandingLayout>
  )
}

export default Login
